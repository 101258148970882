import React from 'react';

import classNames from 'classnames';

import { Deal } from '../../../types/Deal';
import { getFirstNotNullishProperty } from '../../../utils/getFirstNotNullishProperty';
import { getFormattedPrice, getPrice } from '../../GenericComponents/DisplayPrice/getPrice';
import { useHawkWidgetContext } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import styles from '../styles/deal-widget.css';
import { DealWidgetTitleStructure, DealWidgetTitleStructureProperties } from '../types';

import { shouldHideDealWidgetTitle } from './shouldHideDealWidgetTitle';

interface DealWidgetTitleProps {
  deal: Deal;
}

export const DealWidgetTitle: React.FC<DealWidgetTitleProps> = ({ deal }) => {
  const {
    dataLinkMerchant,
    language,
    showPriceRounded,
    modelProductName,
    modelBrand,
    translate,
    genericSharedComponents: { AffiliateLink },
  } = useHawkWidgetContext();

  const formattedPrice = getFormattedPrice(
    getPrice(deal, 'retail'),
    deal,
    false,
    'retail',
    language,
    translate,
    showPriceRounded,
    false,
  );

  const formattedWasPrice = getFormattedPrice(
    getPrice(deal, 'wasPrice'),
    deal,
    false,
    'wasPrice',
    language,
    translate,
    showPriceRounded,
    false,
  );

  if (
    shouldHideDealWidgetTitle({
      modelBrand,
      modelProductName,
      formattedWasPrice,
      formattedPrice,
      dataLinkMerchant,
    })
  ) {
    return null;
  }

  const shouldDisplayModelBrand = modelBrand && modelProductName;
  const shouldCapitalize = getFirstNotNullishProperty<DealWidgetTitleStructure>({
    modelBrand,
    modelProductName,
    formattedWasPrice,
    formattedPrice,
    dataLinkMerchant,
  });

  return (
    <>
      {shouldDisplayModelBrand && (
        <AffiliateLink deal={deal}>
          <span
            className={classNames(
              styles['product-title'],
              shouldCapitalize?.name === DealWidgetTitleStructureProperties.MODEL_BRAND &&
                styles['capitalize'],
            )}
          >
            {modelBrand}{' '}
          </span>
        </AffiliateLink>
      )}
      {modelProductName && (
        <AffiliateLink deal={deal}>
          <span
            className={classNames(
              styles['product-title'],
              shouldCapitalize?.name === DealWidgetTitleStructureProperties.MODEL_PRODUCT_NAME &&
                styles['capitalize'],
            )}
          >
            {modelProductName}
            {!modelProductName.endsWith(':') && ':'}{' '}
          </span>
        </AffiliateLink>
      )}
      {formattedWasPrice ? (
        <AffiliateLink deal={deal}>
          <span
            className={classNames(
              styles['was-price'],
              shouldCapitalize?.name === DealWidgetTitleStructureProperties.FORMATTED_WAS_PRICE &&
                styles['capitalize'],
            )}
          >
            {translate('was')} {formattedWasPrice}
          </span>
        </AffiliateLink>
      ) : null}
      {formattedPrice ? (
        <AffiliateLink deal={deal}>
          <span
            className={classNames(
              styles['price'],
              shouldCapitalize?.name === DealWidgetTitleStructureProperties.FORMATTED_PRICE &&
                styles['capitalize'],
            )}
          >
            {' '}
            {formattedWasPrice ? translate('now') : null} {formattedPrice}{' '}
          </span>
        </AffiliateLink>
      ) : null}
      {dataLinkMerchant ? (
        <AffiliateLink deal={deal}>
          <span
            className={classNames(
              styles['retailer'],
              shouldCapitalize?.name === DealWidgetTitleStructureProperties.DATA_LINK_MERCHANT,
            )}
          >
            {translate('at')} {dataLinkMerchant}
          </span>
        </AffiliateLink>
      ) : null}
    </>
  );
};
