import { DealWidgetTitleStructure } from '../types';

export const shouldHideDealWidgetTitle = ({
  modelBrand,
  modelProductName,
  formattedPrice,
  formattedWasPrice,
}: DealWidgetTitleStructure): boolean => {
  if (!modelBrand && !modelProductName && !formattedPrice) {
    return true;
  }

  if (!modelBrand && !modelProductName && !formattedWasPrice && !formattedPrice) {
    return true;
  }

  if (!modelProductName && !formattedWasPrice && !formattedPrice) {
    return true;
  }

  return false;
};
